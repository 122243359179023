* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.site-container {
  max-height: 100%;
  max-width: 100vw;
  overscroll-behavior: none;
}

body {
  background-color: #1F2833;
}
.carousel {
  display: flex;
  flex-flow: row wrap;
  width: 60%;
  gap: 10px;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin-top: 50px;
}

.arrow {
  font-size: 2em;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.left-arrow {
  /* flex: 1 1; */
  /* flex-grow: 1; */
  left: 0px;
  bottom: 100px;
}

.image-style {
  height: 400px;
  max-width: fit-content;
  /* flex: 1 1; */
  /* flex-grow: 1; */
}

.right-arrow {
  right: 0px;
  bottom: 100px;
}

.image-text {
  display: flex;
  color: white;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 10px;
  justify-content: center;
  text-align: center;
  flex-basis: 100%;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #666;
}




  
.card-container {
    /* width: 15%; */
    width: 300px;
    height: 350px;
    border-radius: 10px;
    box-shadow: 0px 2px 18px 5px rgba(0,0,0,0.3);
    background-color: #1F2833;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* flex-basis: 31%; */
    overflow: hidden;
    transition: all 150ms ease-in-out;
   
}

.card-container:hover {
    transform: translateY(-10px);
    /* background-color: #1F2850; */
    
    cursor: pointer;
}

.logo {
    margin-top: auto;
    height: 50%;
    border-radius: 10px;
    width: 90%;
}

.text-container {
    display: flex;
    padding-left: 30px;
    height: 33%;
    width: 100%;
    align-items: center;
    background-color: #1F2840;
    color: #66FCF1;
    margin-top: auto;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.header-container {
    /*background: url('../../videos/technologyHeader.mp4') center center/cover
    no-repeat;*/
    background-color: #1F2833;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);*/
    object-fit: contain;
}

.header-container > h1 {
    color:white;
    font-size: 100px;
    margin-top: -150px;
}

.header-container > p {
    margin-top: 8px;
    color: white;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 
    'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.header-btns {
    margin-top: 32px;
}

.header-btns .btn {
    margin: 6px;
}

.fa-right-long {
   margin-left: 4px; 
}

@media screen and (max-width: 500px) {

    .header-container > h1 {
        text-align: center;
        font-size: 75px;
    }

    .header-container > p {
        text-align: center;
        font-size: 25px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}



.project-container {
    background-color: #1F2833;
    /* background-color: yellow; */
    min-height: 100vh;
    max-height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);*/
    object-fit: contain;
}

.project-header {
    margin-top: 100px;
    text-align: center;
    font-size: 50px;
    color: white;    
}

.project-subheading {
    margin-top: 30px;
    text-align: center;
    color: white;
    /* font-size: 18px; */
}

.github-link {
    text-decoration: underline;
    color: white;
}

.github-link:hover {
    color: #66FCF1;
}

hr.myhrline {
    background: white;
    color: white;
    height: 5px;
    width: 100px;
    margin: auto;
    margin-top: 15px;
}

@media screen and (max-width: 500px)  {
    .project-subheading {
        font-size: 23px;
    }
}
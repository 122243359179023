.form-container {
    width: 40%;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 100px;
}


.form-container p {
    color: #cd0f51;
}
  
.form-container p::before {
    display: inline;
    content: "⚠ ";
}

.firstName {
    flex-grow: 1;
}

.lastName {
    flex-grow: 1;
}

.email {
    flex-basis: 100%;
}

.message {
    flex-basis: 100%;
}

label {
    font-size: 15px;
    color: #66FCF1;
}

input,
textarea {
    width: 100%;
    font-size: 15px;
    padding: 1rem;
    color: white;
    background-color: #1F2840;
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1rem;
}

textarea {
    min-height: 100px;
    resize: vertical;
}

@media screen and (max-width: 500px) {
    .form-container {
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        margin-top: 40px;
    }

    .firstName{
        flex: 1;
        flex-basis: calc(50% - 10px);
    }
    
    .lastName {
        flex: 1;
        flex-basis: calc(50% - 10px);
    }
}

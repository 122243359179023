.about{
   background: #1F2833;
}

.about > h1 {
    text-align: center;
    font-size: 50px;
    color: white;    
}

hr.myhrline {
    background: white;
    color: white;
    height: 5px;
    width: 100px;
    margin: auto;
    margin-top: 15px;
}

.about_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    margin-top: 80px;
    justify-items: center;
}

.about > h2 {
    color: white;
}


.profilePicSquare {
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.skills_header {
    grid-row: span 3;
    font-size: 30px;
    color: white;  
}

.story_header {
    font-size: 30px;
    color: white;   
}

.story_content {
    margin-left: 175px;
    margin-right: 175px;
    text-align: center;
    font-size: 18px;
    color: white;   
}


.skills {
    margin-top: 15px;
    font-size: 20px;
}


ul {
    color: white;
    padding-left: 3em;
    line-height: 2em;
}

.center {
    justify-self: center;
}

@media screen and (max-width: 500px) {
    .hide-on-mobile {
        display: none;
    }

    .about_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .story_header {
        margin-top: 10px;
        font-size: 30px;
        color: white;   
    }

    .story_content {
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 18px;
        color: white;   
    }
    
}
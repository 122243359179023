.card-grid-container {
    /* background-color: red; */
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    width: 50%;
}

@media screen and (max-width: 500px)  {
    .card-grid-container {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;
    }
}
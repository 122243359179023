.contact-container {
    background-color: #1F2833;
    min-height: 100vh;
    max-height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);*/
    object-fit: contain;
}

.contact-header {
    text-align: center;
    font-size: 50px;
    color: white;    
}

hr.myhrline {
    background: white;
    color: white;
    height: 5px;
    width: 100px;
    margin: auto;
    margin-top: 15px;
}

.contact-subheading {
    margin-top: 30px;
    text-align: center;
    color: white;
    /* font-size: 18px; */
}


@media  screen and (max-width: 500px) {
  .contact-subheading {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 30px;
      /* text-align: center; */
      color: white;
      font-size: 23px;
  }
}

